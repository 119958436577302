import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Proschool_logo,
  book_logo3,
  facebook_icon,
  half_circle_bg_image,
  half_circle_bg_image2,
  instagrme_icon,
  linkedin_icon,
  terms_condition_image,
  terms_condition_image_title,
  twitter_icons,
  youtube_icon
} from "../../assets/images/images";
import HomeNavbar from "../home/components/HomeNavbar";

const TermsandCondition = () => {
  const navigate = useNavigate();
  const globalState = useSelector((state) => state.globalState);
  const subadmin_details = globalState.subadmin_details;
  const handleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior

    // Navigate to the home page
    navigate("/");

    // Use setTimeout to delay the scroll to the specific section
    setTimeout(() => {
      const element = document.getElementById("revolution_sec_new");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the timeout as needed
  };

  return (
    <>
      <Helmet>
        <title> ProSchool.ai Terms - Your Guide to Using Our Platform</title>
        <meta name="description" content=" Understand the terms and conditions for using ProSchool.ai, the AI-enhanced learning management system. Learn more inside." />
        <meta name="keywords" content=" Terms of use, ProSchool.ai guidelines, LMS conditions, educational platform terms, user agreement" />
        {/* Add more meta tags as needed */}
      </Helmet>

      <h1 style={{display : 'none'}}>ProSchool.ai Terms </h1>

      <HomeNavbar />
      <div className="Terms-and-Conditions-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="Terms-and-Conditions-wrapper">
                <div className="Terms-and-Conditions-image-title">
                  <img src={terms_condition_image_title} />
                </div>
                <div className="Terms-and-Conditions-image">
                  <img src={terms_condition_image} />
                </div>
                <div className="Terms-and-Conditions-list-wrapper">
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>1. Acceptance of Terms</h2>
                    <p>
                    By accessing, browsing, or using this website ("Site"), you ("user"), (“you”), & (“your”) acknowledge that you have read, understood, and agree to be bound by these terms and conditions ("Terms"). These Terms constitute a binding legal agreement between you and ProSchool (a brand of Arcot Media Private Limited) ("Company"), (“ProSchool”), (“Platform”), (“we”), (“our”), & (“us”). Your continued use of the Site signifies your acceptance of these Terms. We appreciate your understanding and cooperation in adhering to these Terms, which help ensure a safe and enjoyable experience for all users.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>2. Description of Services</h2>
                    <p>
                    ProSchool offers AI-powered personalized learning management systems designed to enhance educational experiences. Our services include customized learning paths, interactive assessments, real-time analytics, a user-friendly interface, content management, collaboration tools, and ongoing support and training. These services utilize advanced technologies to create a personalized and effective learning experience.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>3. Privacy Policy</h2>
                    <p>
                    We are committed to protecting your privacy. Our platform collects and uses personal information to provide and improve our services. We ensure that your data is handled securely and in accordance with applicable laws and regulations. For more detailed information on how we collect, use, protect, and disclose information about you when you use our services, please review our Privacy Policy.

                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>4. User Responsibilities</h2>
                    <p>
                    As a user of ProSchool's platform, you agree to maintain the confidentiality of your account information and promptly notify us of any unauthorized use. You must provide accurate, current, and complete information during registration and update it as needed. You are responsible for using the platform lawfully and must refrain from prohibited conduct, including posting harmful or explicit content, impersonating others, disrupting the platform, attempting unauthorized access, and using automated means without permission. Additionally, users are responsible for reporting any explicit content, use of profanity, technical issues, content inaccuracies, or suspicious activities encountered on the platform to ProSchool promptly.
                    ProSchool shall not be liable for any loss or damage arising from your failure to comply with the obligations outlined in this clause. By accessing and using the platform, you acknowledge your responsibility to protect the integrity of ProSchool's intellectual assets, Confidential Information, and account security,
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>5. AI Integration for Personalized Learning</h2>
                    <p>
                    ProSchool leverages advanced AI technology to deliver personalized learning experiences tailored to each student’s unique needs and abilities. Our AI algorithms analyze various data points, including performance metrics, learning pace, and preferences, to create customized learning paths that optimize educational outcomes. This integration allows for real-time adjustments and recommendations, ensuring that each student receives the most relevant and effective instructional content. By continuously adapting to individual learning styles and progress, our AI-powered system enhances engagement, motivation, and overall academic achievement.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>6. Assessment and Counseling Through AI</h2>
                    <p>
                    .ProSchool.ai focuses on enhancing academic performance through AI-powered learning tools. It offers a personalized educational journey by analyzing each student's strengths and areas for improvement, and dynamically adjusts content and teaching methodologies to optimize academic outcomes. The platform includes tools that assist educators in lesson planning, grading, and providing targeted academic support, thereby promoting a more effective and focused learning environment. The AI also identifies patterns and potential issues early on, allowing for proactive intervention.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>7. Intellectual Property</h2>
                    <p>
                    All content, features, functionality, and proprietary algorithms on the ProSchool platform, including text, graphics, logos, images, audio, video, software, user data, instructional content, lesson plans, and any AI-generated outputs, are the exclusive property of ProSchool and its licensors. This information is protected by applicable intellectual property laws and shall be treated as Confidential Information. Users are granted a limited, non-exclusive, non-transferable license to access and use the platform solely for its intended educational purposes. Any unauthorized use, reproduction, or distribution of this content, proprietary algorithms, or Confidential Information is strictly prohibited and may result in termination of access to the platform and legal action.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>8. User Conduct</h2>
                    <p>
                    Users are required to maintain ethical and respectful conduct while utilizing, or accessing  the  platform. Any form of misuse, abuse, or breach of these terms and conditions may lead to the suspension or termination of the user's account. Misuse or abuse may include, but is not limited to, unauthorized access, distribution of harmful content, harassment, or engagement in activities that disrupt the normal functioning of the platform. The platform reserves the right to monitor user activity and take appropriate action, including legal measures, to enforce compliance with these terms.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>9. Indemnity & Limitation of Liability</h2>
                    <p>
                    By using ProSchool.ai, you, whether as a Teacher, Student, or an Organization, agree to indemnify, defend, and hold harmless ProSchool.ai, its affiliates, and their respective officers, directors, employees, agents, successors, and assigns (collectively, the "Indemnified Parties") from and against any and all claims, liabilities, losses, damages, costs, and expenses (including reasonable attorneys' fees and court costs) (collectively, "Losses") arising out of or relating to any breach or alleged breach by you of any representation, warranty, or covenant in these Terms and Conditions; any act or omission by you in the creation, distribution, or sale of courses, lessons, assignments, grading rubrics, or any other content on ProSchool.ai if you are a Teacher; any infringement or alleged infringement of any patent, copyright, trademark, trade secret, or other intellectual property right by you in connection with the use of ProSchool.ai;  any claims or disputes arising from your interactions on the platform, including but not limited to the use of the AI assistant during lessons and assignments, or the submission and grading of assignments if you are a Student; and, if you are a Teacher, any claims related to the setting of course prices, the selling of courses on the ProSchool.ai marketplace, and the promotion of such courses by ProSchool.ai.
                    </p>
                    <p>In the event that any claim or demand is made by a third party, including other users, against the Indemnified Parties (a "Third-Party Claim") for which you are obligated to indemnify the Indemnified Parties under these Terms and Conditions, the Indemnified Parties shall promptly notify you of such Third-Party Claim. You shall assume control of the defense and settlement of the Third-Party Claim at your own expense. The Indemnified Parties shall cooperate fully with you in the defense of the Third-Party Claim and may, at their own expense, participate in the defense with counsel of their choosing. You shall not settle any Third-Party Claim without the prior written consent of the Indemnified Parties, which shall not be unreasonably withheld or delayed. Your obligations under this indemnity clause shall not apply to the extent that such Losses result from the gross negligence or willful misconduct of the Indemnified Parties. The obligations under this indemnity clause shall survive the termination or expiration of these Terms and Conditions.
                    </p>
                    <p>The user acknowledges and agrees that the platform's services are provided on an "as-is" and "as-available" basis and that the use of the services is at the user's sole risk. The platform disclaims all warranties, express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>10. Dispute Resolution and Governing Law</h2>
                    <p>
                    These Terms and Conditions will be governed by and interpreted in accordance with the laws of India. Any dispute, controversy, or claim arising out of or related to these Terms and Conditions, or the breach, termination, or invalidity thereof, shall first be resolved through negotiations between the parties. However, if such negotiations fail, the dispute shall be referred to arbitration in accordance with the Arbitration Rules of the Mumbai Centre for International Arbitration ("MCIA Rules") in effect at the time the request for arbitration is made, which rules are deemed to be incorporated by reference in this Section. The seat of Arbitration shall be Mumbai, India. The tribunal shall consist of one (1) arbitrator who shall 
                    be jointly appointed by the Parties. In the event the Parties are unable to jointly decide on an arbitrator within fourteen (14) days of the request for arbitration, such sole arbitrator shall be appointed in accordance with the MCIA Rules. Any award (interim or final) rendered by the Arbitration Tribunal shall be binding on the Parties.
                    </p>
                    <p>This Section is severable from these Terms and Conditions and constitutes an independent binding contract between the Parties, surviving the termination or invalidation of the Terms and Conditions. The Parties shall share the arbitrator's costs equally, while each Party shall remain responsible for its own attorneys’ fees. The arbitrator will provide a detailed written statement of decision, which will form part of the arbitration award. All arbitration proceedings shall be closed to the public and confidential, with all related records permanently sealed, except as necessary to obtain court confirmation of the arbitration award or as required by law.
                    </p>
                    <p>Subject to the foregoing, these Terms and Conditions shall be governed by, and construed and enforced in accordance with, the laws of India. The Parties consent to the exclusive jurisdiction and venue of the competent courts located in Mumbai, India, for any dispute or proceedings relating to Arbitration aforementioned in these Terms and Conditions.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>11. Modification of Terms
                    </h2>
                    <p>
                    The platform reserves the right, at its sole discretion, to modify or update these terms and conditions at any time without prior notice. Users will be notified of any significant changes through appropriate communication channels, such as email or in-platform notifications. Your continued use of the platform following any modifications to these terms constitutes the user's acceptance of the revised terms. It is the responsibility of the user to review the terms periodically to be aware of any changes.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>12.No Reverse Engineering
                    </h2>
                    <p>
                    Users are strictly prohibited from engaging in any activities that involve the reverse engineering, decompiling, disassembling, or otherwise attempting to derive the source code, algorithms, or underlying structure of the ProSchool platform, its software, or any related technologies. This includes, but is not limited to, any efforts to discover or replicate the proprietary algorithms, processes, or other intellectual property incorporated within the platform. Any attempt to perform such actions is considered a violation of these terms and conditions and may result in the immediate termination of your access to the platform. ProSchool reserves the right to pursue all available legal remedies, including, but not limited to, seeking damages and injunctive relief, to protect its intellectual property rights.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>13.Termination
                    </h2>
                    <p>
                    ProSchool reserves the right to terminate or suspend your access to the platform for an indefinite period at any time, without prior notice, for any reason, including, but not limited to, a breach of these Terms,, fraudulent activity, actions or content that contain any viruses, 
                    malware, Trojans, or other computer programs intended to damage, intercept, or expropriate any system, data, or personal information, or conduct that ProSchool deems harmful to other users, third parties, or the platform’s operations. In the event that your access is terminated or suspended, you will not be entitled to any refund of fees or other payments made to ProSchool. Upon termination, your right to use the platform will immediately cease, and any provisions of these Terms that, by their nature, should survive termination, shall remain in effect, including, but not limited to Intellectual Property & Confidentiality, Indemnity & Limitations of Liability and Dispute Resolution and Governing Law.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>14.Severability
                    </h2>
                    <p>If any provision of these Terms is found to be invalid, illegal, or unenforceable by a court of competent jurisdiction, such provision shall be modified to the extent necessary to make it enforceable, while preserving the intent of the provision. If such modification is not possible, the provision shall be severed from these Terms, and the remaining provisions shall continue in full force and effect.</p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>15.Waiver
                    </h2>
                    <p>No waiver of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure to assert a right or provision under these Terms shall not constitute a waiver of such right or provision. Any waiver shall be in writing or communicated by email and must be signed or otherwise agreed to by the party granting the waiver.</p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>16.Force Majeure
                    </h2>
                    <p>ProSchool shall not be liable for any failure to perform its obligations hereunder where the failure results from any cause beyond ProSchool's reasonable control, including, but not limited to, mechanical, electronic, or communications failure, acts of God, war, terrorism, natural disasters, strikes, civil unrest, or governmental demands or restrictions.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>17.Third-Party Beneficiaries
                    </h2>
                    <p>These Terms are for the sole benefit of you and ProSchool, and they do not confer any rights or benefits to any third parties. You may not assign or transfer your rights, obligations, or access to account, and credentials under these Terms without the prior written consent of ProSchool. Any unauthorized assignment or transfer shall be null and void.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>18.Notices</h2>
                    <p>All notices, requests, or other communications required or permitted to be given under these Terms shall be in writing and delivered to ProSchool at its official contact email or physical address, as provided on the platform. Notices to users shall be delivered to the email address provided at the time of registration or by other means deemed appropriate by ProSchool.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>19.Headings
                    </h2>
                    <p>The headings used in these Terms are for convenience only and shall not affect the interpretation of the provisions contained herein.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>20.Entire Agreement
                    </h2>
                    <p>These Terms, along with any other legal notices and agreements published by ProSchool on the platform, constitute the entire agreement between you and ProSchool concerning the use of the platform. They supersede all prior agreements, understandings, or representations, whether written or oral, regarding such subject matter.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>21.Contact Information
                    </h2>
                    <p>For any queries or a detailed walkthrough of our platform, please contact us at support@arcitech.ai. We are here to assist you with any questions or concerns you may have about our services or the Terms & Conditions.
                    </p>
                  </div>  
                </div>
                <div className="half-circle-bg-image">
                  <img src={half_circle_bg_image} />
                </div>
                <div className="half-circle-bg-image2">
                  <img src={half_circle_bg_image2} />
                </div>
                <div className="book-logo3">
                  <img src={book_logo3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-section" id="footer-section">
        <div className="footer-wraper">
          <div className="footer-link-folder">
            <div className="proschool-logo">
              <img src={subadmin_details ? subadmin_details?.school_image : Proschool_logo} />
            </div>
            <p>We are more than just an LMS; we are a catalyst for comprehensive educational transformation. ProSchool.ai brings a multidimensional approach to education, 
              contrasting with traditional methods.
            </p>
            {/* <div className="footer-Join-our-newsletter-folder">
              <p>Join our newsletter</p>
              <div className="footer-Join-our-newsletter-search-folder">
                <input type="email" placeholder="Your email"></input>
                <button>Subscribe</button>
              </div>
            </div> */}
          </div>
          <div className="footer-Quick-Links-folder">
            <h2>Quick Links</h2>
            <ul className="footer-Quick-Links">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <a href="revolution_sec_new" onClick={handleClick}>
                  Features
                </a>
              </li>
              <li>
                <NavLink to="/about">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/terms-and-conditions">Terms & Conditions</NavLink>
              </li>
              <li>
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-Quick-Links-folder">
            <h2>Contact</h2>
            <div className="footer-contact">
              {/* <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-phone"></i>
                </a>
                <span>
                <a href="tel:+1 (646) 222-3901">{subadmin_details ? subadmin_details?.phone : '+1 (646) 222-3901'}</a>
                </span>
              </div> */}
              <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-envelope"></i>
                </a>
                {subadmin_details ? (
                  <a href={`mailto:${subadmin_details?.email}`}>{subadmin_details.email}</a>
                ) : (
                  <a href="mailto: info@proschool.ai">info@proschool.ai</a>
                )}


              </div>
              {/* <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-location-dot"></i>
                </a>
                {subadmin_details ? (
                  subadmin_details?.location
                ) : (
                  <a
                    href="https://www.google.com/maps/place/Arcitech+-+AI+Software,+Web,+App+Development/@19.0020445,72.8229651,16z/data=!4m10!1m2!2m1!1sA-802,+2nd+floor,+Trade+Link,+Kamala+Mills,+Lower+Parel,+Mumbai,+Maharashtra+400013!3m6!1s0x3be7ce9336955555:0x743645ef3c0b1a05!8m2!3d19.0033511!4d72.8274615!15sClNBLTgwMiwgMm5kIGZsb29yLCBUcmFkZSBMaW5rLCBLYW1hbGEgTWlsbHMsIExvd2VyIFBhcmVsLCBNdW1iYWksIE1haGFyYXNodHJhIDQwMDAxM1pPIk1hIDgwMiAybmQgZmxvb3IgdHJhZGUgbGluayBrYW1hbGEgbWlsbHMgbG93ZXIgcGFyZWwgbXVtYmFpIG1haGFyYXNodHJhIDQwMDAxM5IBEHNvZnR3YXJlX2NvbXBhbnmaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTkxiMHBEVm1ObkVBReABAA!16s%2Fg%2F11lt56gqrs?entry=ttu"
                    target="_blank"
                  >
                    A-802, 2nd floor, Trade Link, Kamala Mills, Lower Parel,
                    Mumbai, Maharashtra 400013
                  </a>
                )}
              </div> */}
            </div>
          </div>
          <div className="footer-get-in-touch-folder">
            <span>Get in touch</span>
            <div className="footer-social-link-folder">
              <a
                href="https://www.facebook.com/proschool.ai1"
                target="_blank"
              >
                {/* <i className="fa-brands fa-facebook"></i> */}
                <img src={facebook_icon}/>
              </a>
              <a 
              href="https://twitter.com/Proschoolai" 
              target="_blank"
              >
                {/* <i class="fa-brands fa-x-twitter"></i> */}
                <img src={twitter_icons}/>
              </a>
              <a
                href="https://www.instagram.com/proschool.ai/"
                target="_blank"
              >
                {/* <i className="fa-brands fa-instagram"></i> */}
                <img src={instagrme_icon}/>
              </a>
              <a
                href="https://www.linkedin.com/company/proschool-ai"
                target="_blank"
              >
                {/* <i class="fa-brands fa-linkedin"></i> */}
                <img src={linkedin_icon}/>
              </a>
              <a 
              href="https://www.youtube.com/@ProSchoolAI" 
              target="_blank"
              >
                {/* <i class="fa-brands fa-youtube"></i> */}
                <img src={youtube_icon}/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsandCondition;
